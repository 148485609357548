/*
* @component controls-producto
* @description Eliminar, modificar la cantidad del producto
*/
.controls-producto{

    &.cart {
        position: absolute;
        bottom: 15px ;
        right: 15px;
    }


    
    &.product {
        min-width: 100%;


        .ant-space-item{
             width: 100%;
             .ant-input-group-wrapper{
                 width: 100%;
             }
        }

        .control-input-producto{
            min-width: 100px !important;
            /*
            * @component ant-input
            * @description Input del producto (codigo de ANTD modificado)
            */
            .ant-input{
                min-width: 100% !important;
            }
        }
        margin-bottom: 1em;;
    }






    /*
    * @component Icon
    * @description Todos los iconos deben de estár en el mismo color.
    */
    .icon {
        color: #A4A4A4 !important;
    }

    /*
    * @component control-input-producto
    * @description Input del producto
    */
    .control-input-producto{
        
        /*
        * @component ant-input
        * @description Input del producto (codigo de ANTD modificado)
        */
        .ant-input{
            height: 34px !important;
            max-width: 60px;
            border-top: 1px solid #D8D8D8 !important;
            border-bottom: 1px solid #D8D8D8 !important;
            border-left: none !important;
            border-right: none !important;
            text-align: center;
            margin-bottom: 0 !important;
        }

        /*
        * @component addBefore
        * @description CSS del boton de +
        */
        .ant-input-group-addon{
            padding: 0 !important;
            background: none !important;
            border: none !important
        }

        /*
        * @component ant-input-group-addon
        * @description CSS del fondo, sección inicial
        */
        .ant-input-group-addon:last-child{
            border-top: 1px solid #D8D8D8 !important;
            border-bottom: 1px solid #D8D8D8 !important;
            border-right: 1px solid #D8D8D8 !important;
        }

        /*
        * @component ant-input-group-addon
        * @description CSS del fondo, sección final
        */
        .ant-input-group-addon:first-child{
            border-top: 1px solid #D8D8D8 !important;
            border-bottom: 1px solid #D8D8D8 !important;
            border-left: 1px solid #D8D8D8 !important;
        }

    }
    
    /*
    * @component control-input-delete-producto
    * @description Botón de eliminar producto.
    */
    .control-input-delete-producto{
        border: 1px solid #D8D8D8 !important;
    }
}
