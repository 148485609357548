.store{
    margin-top: 40px;
    // min-width: 1900px;
}

.store-section.responsive{
    max-width: 1300px;
    width: 100%;
    margin: 0 auto !important;
}

.store-head-section{
    margin-bottom: 24px;
}

.store-icon{
    &-grid-view > *{
        fill: rgba(#CCFF1B, 0.22);
    }

    &-list-view > *{
        fill: rgba(#CCFF1B, 0.22);
    }

    &-active > *{
        fill: rgba(#CCFF1B, 1);
    }
}

.store-grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 24px;
    column-gap: 24px;
}

.store-product{
    margin-bottom: 0.75rem !important;
}



.store-pagination{
    margin-top: 24px;
}

.store-pagination .ant-pagination-item{
    & * {
        color: #CCFF1B;
        background-color: rgba(#CCFF1B, 0.22);
    }
    &:hover{
        border-color: #CCFF1B;
    }
    &:hover:not(.ant-pagination-item-active) *{
        color:#CCFF1B;
    }
}

.store-pagination .ant-pagination-item-active{
    border-color: transparent !important;
    & *{
        color: white;
        background-color: rgba(#CCFF1B, 1);
    }
    &:hover *{
        color: white;
    }
    &:hover{
        border-color: none;
    }
}

.store-pagination .ant-pagination{
    display: flex !important;
    align-items: center;
    justify-content: flex-end;

    & > .ant-pagination-prev, & > .ant-pagination-next, &-options{
        display: none !important;
    }

    & > * {
        display: block !important;
    }

    & > .ant-pagination-total-text {
        flex-grow: 1;
        justify-self: flex-start;
    }
}

.store-pagination .ant-pagination-jump-next, .store-pagination .ant-pagination-jump-prev {
    background-color: rgba(#CCFF1B, 0.22);

    & * {
        color: #CCFF1B !important;
    }
}

.store-pagination .ant-pagination-options{
    display: none !important;

    
}

.store .ant-slider-handle{
    background-color: rgba(#CCFF1B, 1) !important;
}

.store .ant-slider-track{
    background-color: rgba(#CCFF1B, 1) !important;
}

.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-inner{
    background-color: transparent !important;
    box-shadow: none;
    color: #575757;
}

.ant-slider-tooltip.ant-tooltip-placement-bottom .ant-tooltip-arrow-content{
    background-color: transparent !important;
}


.store-categories-select > .ant-select-selector{
    height: auto !important;
    padding: 0.5rem 1rem !important;
}


.store-card-title{
    margin: 0.5rem 0 1rem 0  !important;
    height: 3em;
    font-size: 1em !important;
    overflow: hidden;
}

.store-card-price{
    margin: 0 !important;
    font-size: 1.125rem;
}



/* MENU SECTION */
.store-menu {
    .ant-menu-root{
        &.ant-menu-inline{
            border-right: none !important;
        }

        & > .ant-menu-submenu .ant-menu-submenu-title, & > .ant-menu-item{
                padding-left: 0px !important;

                .ant-menu-title-content{
                    font-weight: 600 !important;
                    font-size: 16px;
                }

                &[aria-expanded="false"] .ant-menu-submenu-arrow{
                    opacity: 0;
                }

                &[aria-expanded="false"]:hover .ant-menu-submenu-arrow, &[aria-expanded="true"] .ant-menu-submenu-arrow{
                    opacity: 1;
                }
        }

        & .ant-menu-sub.ant-menu-inline{
            background-color: transparent;
        }

        & .ant-menu-sub .ant-menu-item {
                padding-left: 0px !important;
        }
    }
}


/* PRODUCT PAGE */
.store-producto{

    &-gallery{
        .ant-carousel{
            width: 100%;
        }
    }
    
    &-carousel .ant-image-img, &-carousel .ant-image{
        height: 450px !important;
    }
    
    &-minis {
        position: relative;

        .ant-space-item{
            width: 20% !important;
            min-width: 120px !important;
        }

        .ant-image-img, .ant-image{
            width: 100% !important;
            height: 120px !important;
        }

        .ant-image-img{
            cursor: pointer !important;
        }

        .ant-image:has(.ant-image-img.selected)::after{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,0,0,0.5);
        }
    }

    &-categoria{
        display: block !important;
        font-weight: 500;
        margin-bottom: 0.75rem;
    }

    &-title{
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 2rem;
        font-size: 24px;
    }
    
    &-price{
        margin-bottom: 1rem;
        font-size: 22px;
    }
    &-descripcion-title{
        padding-left: 10px;
    }
    
    &-descripcion{
        margin-top: 0.5rem;
        border: solid 1px #D0CFCF;
        margin-right: 2rem;
        border-radius: 8px;
        min-height: 200px;
        max-height: 200px;
        overflow-y: auto; 
        padding-left: 10px;
    }
    
    &-descripcion{
        border: none !important;
    }   
}