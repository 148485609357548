.video-mainhead {





    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    opacity: 0.7;
    z-index: 0;
    object-fit: cover;

}

.landing {
    overflow-x: hidden;
    
}

body{
    background-color: #000;
}

.logo-slider {
    margin-top: 7em !important;
}

.producto-desc {
    width: 80%;
    text-align: center;
    font-size: 18px;
    color: #535b65 !important;
}

.precios {
    height: 100%;
}

.producto-buy {
    background-color: transparent !important;
    border-radius: 0px !important;
    border: 2px solid #CCFF1B !important;
    font-weight: 700 !important;
    text-transform: uppercase;
    z-index: 9;
    min-width: 140px;
    height: 36px !important;
    font-size: 16px !important;


}

.producto-buy::before {
    content: '' !important;

    width: 0;
    height: 100%;
    top: 0;
    left: 50%;
    transition: .35s ease;
    background-color: #CCFF1B;
    transform: translate(-50%, 0) skew(25deg);

}

.producto-buy:hover::before {
    width: 150%;
}

.producto-bg {
    position: absolute;
    width: 100vw;
    transform: translateY(-20%);
    object-fit: cover;
    z-index: 2;
}

.producto-img {
    z-index: 3;
    width: 50vw;
}

.producto-precio {
    margin-top: 3em;
    width: 80% !important;

}

.producto-precio .ant-card-body {
    padding: 0 !important;
    height: 90px;
}

.card-encuentra .ant-form-item-label label {
    color: #9ca4ab !important;
    font-weight: 600;
}

.card-encuentra .ant-select {
    border: 2px solid #9ca4ab !important;
    font-weight: 600;
}

.card-encuentra .ant-select-selector {
    border: unset !important
}

.card-encuentra .titulo {
    text-transform: uppercase;
    font-family: 'Teko', sans-serif;
    color: #000000;
}

.producto-precio-modelo {
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    color: #192330;
}

.producto-precio-text {
    font-family: 'Teko', sans-serif;
    color: #CCFF1B !important;
    font-weight: 600;
    font-size: 36px;
    line-height: 1;
    text-transform: uppercase;
}

.flecha-derecha {

    width: 0;
    height: 0;
    border-top: 47px solid transparent;
    border-bottom: 47px solid transparent;

    border-left: 47px solid #fff;

}

.carousel-container {
    width: 80% !important;
}

.carousel-cards .react-multi-carousel-item {
    max-width: 387px;
}

.card-categ {
    border-radius: 0 !important;
    margin-right: 1rem !important;
    min-height: 250px;
    margin-bottom: 5px !important;
    width: 350px;
    box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.1);
}

.card-categ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-image: url(../../../../public/images/hover.png);
    transition: .5s cubic-bezier(0, 0, 0.35, 1.15);
    opacity: 0;
    transform: translateX(10px);
}

.card-categ:hover::before {
    opacity: 1;
    transform: translateX(0);
}

.card-categ img {
    position: absolute;
    transform: translateX(-23%) translateY(-15%);
}

.categ-info {
    min-height: 205px !important;
}

.categ-title {
    font-family: 'Teko', sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 28px;
}

.producto-info-img {
    width: 89px;
    max-height: 89px;
    margin-right: 0 !important;

}

.producto-info-img img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;

}

.producto-info-label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 0 30px;



}


.producto-info {
    box-shadow: 0px 0px 30px rgba(25, 35, 48, 0.08) !important;
    border-radius: 0 !important;
    border: unset !important;
    min-width: 270px;
    height: 90px;
}

.producto-info::before {
    content: '';
    position: absolute;
    border: 45px solid transparent;
    border-left: 20px solid #fff;
    top: 0;
    left: 100%;
    height: 100%;
}



.ventajas {
    margin-top: 5rem !important;
    
    background-color: #535b65;
}

.opinion{
    background-image: url(../../../../public/images/effect-bg-feedback.svg);;
}

.ventajas::before {
    content: '';

    top: 0;
    left: 0;
    width: 100%;
    height: 36px;
    background-color: #FFFFFF;
    z-index: 2;
    clip-path: polygon(0 0, 0 100%, 100% 0);
}

.ventajas::after {
    content: '';

    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    height: 44px;
    z-index: 2;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 25% 100%);
}

.ventajas h1 {
    color: #FFFFFF !important;
}

.ventajas {
    color: #FFFFFF !important;
}

.ventaja-title {
    color: #FFFFFF !important;
}

.subscribe {
    margin-top: 5rem !important;

    background-color: #535b65;
}

.subscribe::before {

    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    background-color: #FFFFFF;
    z-index: 2;
    clip-path: polygon(0 0, 100% 100%, 100% 0);

}

.subscribe::after {

    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #FFFFFF;
    height: 44px;
    z-index: 2;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0, 75% 100%);



}

.subscribe .ant-form-item-control-input input{

   border-radius: 0 !important;
   border: 2px solid #9ca4ab !important;
   background-color: transparent !important;
    
}

.subscribe .ant-form-item-control-input button{

    border-radius: 0 !important;
    border: 2px solid #CCFF1B !important;
     
 }

 .subscribe .ant-form-item-control-input button:hover{

    border-radius: 0 !important;
    background-color: #535b65 !important;
    color: #CCFF1B !important;
     
 }

.subscribe h1 {
    color: #FFFFFF !important;
}

.subscribe {
    color: #FFFFFF !important;
}

.subscribe .subtitle{
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px !important;
    color: #FFFFFF !important;
}

.opinion {
    color: #535b65 !important;
}


.landing-slider-slide {
    height: calc(100vh - 150px);
    padding: 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 120;
}

.landing-slider-slug {
    font-size: 4.75rem !important;
    color: white !important;
    font-weight: 400 !important;
    margin-bottom: 0 !important;
}


@media (max-width: 600px) {
    .landing-slider-slug {
        font-size: 2.75rem !important;
        width: 90%;
    }
}



.landing-slider-brand {
    width: 90%
}

.landing-slider-button-link {
    height: auto !important;
    width: 90%;
    padding: 0.25rem 0 !important;
    border: 4px solid white !important;
    border-radius: 0 !important;
    background-color: transparent !important;
    font-size: 2.125rem !important;
    color: white !important;
    font-weight: 700 !important;
    text-align: center;

}



/*section*/

.row-langing-images {
    width: 100% !important;
    text-align: center !important;

}

.row-langing-images>.ant-col {
    text-align: center !important;
    margin: auto !important;
}

.card-landing {
    padding: 0px !important;

    .ant-card-body {
        padding: 12px !important;
    }

    text-align: center !important;
    margin: auto !important;
}

.row-img {
    background-size: cover;
    background-position: center !important;
    height: 700px !important;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding-top: 2.5rem;
    margin: auto !important;
}

.ttl-card-landing {
    color: #FFF !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 2.5em !important;
    line-height: 45px !important;
    text-align: center !important;
    margin-bottom: 40px !important;
}

.btn-card-landing {

    background: transparent !important;
    color: #FFF !important;
    border: 1px solid #FFFFFF !important;
    box-sizing: border-box !important;
    border-radius: 0px !important;
    width: 70% !important;
    max-width: 350px !important;
    height: auto !important;


    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 25px !important;
    line-height: 37px !important;
    text-align: center !important;
    color: #FFFFFF !important;

}


.btn-card-landing:hover {

    border: 1px solid #CCFF1B !important;
    color: #CCFF1B !important;



    text-align: center;
}


.custom-left-arrow {
    position: absolute !important;
    left: 30px;
    z-index: 1;
    border: 1px solid grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
    transform: rotate(135deg);
  }
  .custom-left-arrow:hover {
    opacity: 1;
  }