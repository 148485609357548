 .card-bienvenido {
   background: transparent;
   /* background-image: url("/images/bgBienvenido.png"); */
   background-repeat: no-repeat;
   /* background-size: cover; */
   border-radius: 25px !important;
   border: none !important;
   height: 100px;
   width: 98%;
   max-width: 670px;
 }




 .main-dashboard-title {
   font-weight: bold;
   font-size: 30px;
   color: #333539;
 }

 .card-text {
   font-weight: 600;
   font-size: 15px;
   color: #333539;
   margin-bottom: 1rem;
 }

 .card-container {
   border-radius: 10px;
   border: none;
   box-shadow: 0px 5px 15px rgba(209, 213, 223, 0.5);
   height: 100%;
   margin-bottom: 0.5rem;
 }

 .card-container .ant-card-head{
   border: none!important;
 }

 .card-container .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
   font-size: 20px;
 }

 .card-icon {
   background: transparent !important;
 }

 .card-count {
   font-size: 25px;
   font-weight: 600;
 }

 .card-icon svg {
   color: #bbea18;
 }