.ovf-auto{
    overflow: auto !important;
}

/*
 * @component Title 
 * @description Titulo de los steps
 */
.steps-title{
    padding-left: 20px;
    margin: 2em 0;
}

/*
 * @component list-productos-shopping-cart
 * @description  Para la lista de productos del shopping cart 
 */
.list-productos-shopping-cart{
    width: 100%;
    max-height: 470px;
    overflow: auto;
    
    
    /*
    * @component product-shopping-cart
    * @description Lista de productos de shopping
    */
    .product-shopping-cart {

        border: none !important;
        margin-bottom: 1.5em;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);

        /*
        * @component ant-card-body
        * @description Quitamos el padding general
        */
        .ant-card-body {
            padding: 0;

        }

        /*
        * @component ant-card-body
        * @description Quitamos el padding de la lista
        */
        .ant-list-item {
            padding: 0 0 !important;
        }

        /*
        * @component producto-index-container
        * @description CSS para el número que está al principio de los cards
        */
        .producto-index-container {        
            position: relative;

            /*
            * @component producto-index
            * @description Lo posiciionamos en el medio de las cards
            */
            .producto-index{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-70%);
                color: #CCFF1B;
            }
    
        }


        /*
        * @component producto-image
        * @description Posicion de la imagen
        */
        .producto-image {
            border-radius: 10px;
        }



        .producto-content {
            .product-description{
                margin-bottom: 8px !important;
            }

            .product-tags{ 
                margin-bottom: 8px !important;
            }

            .product-precio{
                $colorStats: #CCFF1B; 
                color: $colorStats;
                .ant-statistic-content-prefix, .ant-statistic-content-value-int, .ant-statistic-content-value-decimal{
                    color: $colorStats;
                }
            }
        }

        // .product-description{
        //     position: relative;
        //     .producto-tags{
        //         position: absolute;
        //         left: 0;
        //         bottom: calc(-100% - 7px);
        //     }

        //     .precio {
        //         position: absolute;
        //         // bottom: calc(-100%);
        //         // bottom: calc(-0 - 7px);
        //     }

        // } 


    }
}







/*
 * @component CardDetail 
 * @description Estilos del cardDetail del carrito de compras
 */
.card-detail-shop{
    width: 350px;


    .ant-card-head{
        border-bottom: none!important;
        
    }
    .ant-card-head .ant-card-head-title{
        padding: 10px 0 0 0!important;
    }

    .card-item-cantidad{
        font-size: 15px;
        font-weight: 600;
    }

    .card-item-title{
        font-size: 12px;
        color: #8D8D8D;
        display: flex;
        white-space: pre-wrap;
    }
    .card-item-free span{
        font-size: 12px;
        color: #969696;

    }
    .card-item-costo,.card-item-costo span{
        font-size: 15px!important;
        font-weight: 500!important;
        text-align: right!important;
    }
    .card-item-costo-total{
        font-size: 20px!important;
        font-weight: 600!important;
        color: #CCFF1B;
        text-align: right!important;

    }
    .card-footer{
        font-size: 12px;
        color: #8D8D8D;
        // white-space: wrap;
        word-wrap: break-word;
    }

}



.container-brands {
    padding: 30px 20px 30px 20px !important;
    margin: 36px auto 36px auto;
    border: 1px solid #CCFF1B;
    border-radius: 12px;
    position: relative;

    display: inline-block;

    .ant-space-item{
        margin: 0 12px;
        // padding: 12px 0;
    }

    
    .ant-space-item img{
        width: 90%;
        max-width: 100px !important;
    }


    .ant-space-item:first-child{
        padding: 0 12px !important;
        background-color: white;
        position: absolute;
        top: -30px;
        left: 50%;
        min-width: 200px;
        transform: translateX(-50%);
        

        .ant-typography {
            font-size: 15px !important;
            font-weight: 15px !important;
        } 

        .ant-typography img{
        width: 40% !important;
        max-width: 90px !important;
    }

    }

}


.button-pay{
    background: #CCFF1B;

}