/*
 * @component landing-section
 * @description Representa una sección del landing page o de la sección publica
 */
.landing-section {
    margin: 12px auto 24px auto;
}


/*
 * @component landing-section
 * @description Limita el tamaño del website a 1200px
 */
.landing-section.responsive {
    max-width: 1200px;
    width: 100%;
}

.card-encuentra {
    z-index: 999;
    width: 80%;
    top: -30px !important;
    border-radius: 0 !important;

    .ant-form-item{
        margin: unset !important;
        border-radius: 0 !important;
    }

    .ant-select-selector {
        border-radius: 0 !important;
    }
}

