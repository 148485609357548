.analytics {
    border-radius: 15px;
    text-align: center;
    margin: 10px;
    height: 96% !important;
    padding: 10px;

}

.analytics .ant-card-head:first-child {
    background: rgba(189, 234, 24, 0.53) ;
}

.cardAnalitics .ant-card-head {
    background: #fff !important;
}

.cardAnalitics .ant-card-body {
    color: #000;
    border-radius: 10px !important;
    width: 90% !important;
    margin: 5% !important;
    min-height: 80px;

}

.cardAnalitics .ant-card-body {
    border: 1px solid rgba(170, 80, 254, 0.53) !important;
}