.direcciones{
    margin: 2rem 0;
}

.direcciones-section{
    margin: 1rem 0;
}

.direcciones-title{
    margin: 2rem 0 0 0;

    &:nth-child(1){
        margin: 0;
    }
}

.direcciones-card{
    width: 100%;
    border: 1px solid #CCFF1B99 !important;

    & p{
        margin: 0;
    }
}

.direcciones-radio-group{
    width: 100%;
    margin: 1rem 0;
}

.direcciones-radio{
    width: 100%;
    display: flex !important;
    align-items: center !important;
    margin-bottom: 1rem !important;

    &:nth-child(2){
        margin-bottom: 0;
    }

    & > *{
        display: block;
    }

    & > *:nth-child(2){
        width: 100%;
    }
}
.direcciones-input{
    padding: 0.75rem 0.75rem !important;
}
