.cnt-login{
    border-radius: 20px !important;
    z-index: 100 !important;
}

.cnt-login .col-form{
    padding: 3rem 2rem  1rem 2rem !important;
}

.cnt-login .col-form form{
    margin-bottom: 150px !important;
}

.cnt-login .col-form .h3-title{
    font-style: normal;
    font-weight: bold;
    font-size: 2.8em;
    line-height: 70px;
    /* identical to box height, or 117% */
    letter-spacing: -1.34px;
    color: #CCFF1B;
}

.col-img{
    background-size: cover !important;
    background-repeat: no-repeat !important;
    border-radius: 0px 25px 25px 0px !important;
    background-position: center !important;
}

.txt-pie-modal{
    font-size: 12px !important;
    line-height: 28px !important;
    letter-spacing: -0.18px !important;
    color: #032D23 !important;    
    
}

.col-form a{
    color: #CCFF1B !important;
    font-weight: 600;
}
.col-form a,
.txt-pie-modal span{
    color: #CCFF1B !important;
}

.alg-l{ float: left ;  text-align: left ;    margin-right: 22px;}
.alg-r{ float: right ; text-align: right ;   margin-left:  22px;}


.button-login{
    background: #CCFF1B !important;
    border-radius: 6px !important;
    font-weight: 300 !important;
    font-size: 18px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px !important;
    color: #FFFFFF !important;
    height: auto !important;
     margin: auto !important;
     margin: 20px auto !important;
}

.input-login {
    height: 55px;
    background:white !important;
    border-radius: 5px!important;
    border: 2px solid rgba(2, 2, 2, 0.45) !important; 
    margin-bottom: 20px !important;
}

.input-login svg {
    color: #CCFF1B !important;

}

