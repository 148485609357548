@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&family=Poppins:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap');
/*CARD TASK CLIENT CSS*/

/*
 * @component CardTaskClient
 * @description estilos del card principal
 */

.card-task {
    margin: 0.5rem 0 0 0!important;
    min-width: auto;
    max-width: 365px;
    border-radius: 12px!important;
}

/*
 * @component CardTaskClient
 * @description estilos de la descripcion del card
 */

.card-task-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component CardTaskClient
 * @description estilos del tag del card
 */

.card-task-tag {
    border-radius: 4px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding: 5px 8px!important;
}

/*
 * @component CardTaskClient
 * @description Quita el borde del head del card
 */

.card-task .ant-card-head {
    border: none!important;
}

/*
 * @component CardTaskClient
 * @description estilos del titulo del head del card
 */

.card-task .ant-card-head .ant-card-head-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2E4D;
}

/******************* CARD TASK NAME CSS ********************************/

/*
 * @component CardTaskStep
 * @description estilos del card principal
 */

.card-task-name {
    margin: 0 0 0 0!important;
    min-width: auto;
    max-width: 365px;
    border-radius: 8px!important;
}

/*
 * @component CardTaskStep
 * @description hace un espacio interno al cover (imagen) para que se vea dentro del card
 */

.card-task-name .ant-card-cover {
    padding: 5px 8px;
}

/*
 * @component CardTaskStep
 * @description estilos de la "imagen" en este caso background color del card
 */

.card-task-color {
    height: 100px;
    width: 100%;
    border-radius: 8px;
}

/*
 * @component CardTaskStep
 * @description estilos de la fuente del meta del card
 */

.card-task-name-meta .ant-card-meta-detail {
    font-family: Poppins;
    font-style: normal;
}

/*
 * @component CardTaskStep
 * @description estilos del meta title del card
 */

.card-task-name-meta .ant-card-meta-detail .ant-card-meta-title {
    /* Heading */
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2E2E4D!important;
}

/*
 * @component CardTaskStep
 * @description estilos del meta de la descripcion del card
 */

.card-task-name-meta .ant-card-meta-detail .ant-card-meta-description {
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component CardTaskStep
 * @description estilos del meta del card del titulo del card
 */

.card-task-list-meta .ant-list-item-meta-title {
    font-family: Poppins;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #5E5E7A;
}

/*
 * @component ProjectManager -> div
 * @description Margen del contenedor de los task para poder ver el background y la separacion de Cols
 */

.col-list-task-margin {
    padding: 10px;
}

/*
 * @component CardSaldosGraph
 * @description Borde del card
 */

.card-saldos-dashboard {
    border-radius: 10px!important;
}

/*
 * @description tamaño del height para que se vea la grafica
 */

.card-saldos-dashboard .ant-card-cover {
    height: 150px;
}

/*
 * @description Elimina el padding para que el contenido se ajuste mejor
 */

.card-saldos-dashboard .ant-card-body {
    padding: 0;
}

/*
 * @description Estilos de la fuente del HEAD del card
 */

.card-saldos-dashboard .ant-card-head {
    border: none;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #858997;
}

/*
 * @component CardOperations
 * @description Asigna el fondo transparente
 */

.card-operations-dashboard {
    background: transparent!important;
}

/*
 * @description Estilos del Head del Card
 */
.card-operations-dashboard .ant-card-head {
    border: none;
    padding: 0 1rem;
    min-height: 15px!important;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: -0.009em;
    color: #262631;
}

/*
 * @description Estilos del Head del Card
 */
.card-operations-dashboard .ant-card-head .ant-card-head-wrapper .ant-card-head-title {
    padding: 0!important
}

/*
 * @description Modificacion al padding del Body del Card
 */
.card-operations-dashboard .ant-card-body {
    padding: 0 1rem;
}
/*
 * @description Modificacion al padding del Meta del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta {
    padding: 1rem 0;
}

/*
 * @description Estilos de fuente del Meta title del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-title {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 35px;
    color: #2E2E3A;
}

/*
 * @description Estilos de fuente del Meta descripcion del Card
 */
.card-operations-dashboard .ant-card-body .ant-card-meta .ant-card-meta-detail .ant-card-meta-description {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #656575;
}

/*
 * @description Estilos del texto de porcentaje e icono  rojos
 */
.operations-down, .operations-down svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #FD4438;
}

/*
 * @description Estilos del texto de porcentaje e icono verdes
 */
.operations-up, .operations-up svg {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.009em;
    color: #24CA49;
}

/*
 *
 * @description Card que se encuentra dentro del card de Operations,Asignacion de estilos del card
 */
.card-operations-dashboard-inner {
    border-radius: 16px!important;
    height: 225px;
}
/*
 * @description Modifacion de espacios y tamaño del head del card
 */
.card-operations-dashboard-inner .ant-card-head {
    padding: 0 1rem;
    height: 5px;
}
/*
 * @description Modifacion de espacios del body para que no abarque tanto espacio
 */
.card-operations-dashboard-inner .ant-card-body {
    padding: 0 1rem;
}
/*
 * @description Estilos del texto del card
 */
.card-operations-dashboard-inner-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 25px;
    color: #27272E;
    padding: 0.4rem 0;
    margin: 0.5rem 0;
}
/*
 * @description Estilos del texto del card para el progress bar
 */
.card-operations-dashboard-progress-text {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #84859E;
    margin: 2px 0px;
}

/*
 *
 * @description Contenedor del icono del Tools del card
 */
.box-icon-rounded {
    background: rgba(0, 205, 152, 0.2);
    border-radius: 8px;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    justify-self: center;
    vertical-align: middle;
}



@media screen and (min-width:1800px) {
    .col-list-task-margin {
        max-width: 450px;
    }
    .card-task-name, .card-task {
        display: block;
        margin: 0 auto 10px auto !important;
    }
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo{
    border-radius: 10px !important;
    min-height: 219px;
}

/*
 * @component CardSaldo
 * @description el titulo del Card
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-title{
    color: #858997;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 1.2rem;
}

/*
 * @component CardSaldo
 * @description estilos para el monto total de las cuentas
 */
.card-saldo .card-saldo-main-meta .ant-card-meta-description{
    font-weight: bold;
    font-size: 30px;
    color: #000;
    margin-bottom: 15px;
}

/*
 * @component CardSaldo
 * @description estilos del small
 */
.card-saldo .card-saldo-main-meta small{
    font-size: 14px;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-title{
    font-weight: 600;
    font-size: 12px;
    color: #858997;
}

/*
 * @component CardSaldo
 * @description estilos del meta del card de Saldo al momento de Finanzas
 */
.card-saldo .card-saldo-sub-meta .ant-card-meta-description{
    font-weight: 600;
    font-size: 12px;
    color: #0047FF !important;
    margin-bottom: 20px;
}

/*
 * @component CardCuenta
 * @description limita el tamaño de cada contenedor principal del carrousel
 */
.carousel-cards .react-multi-carousel-item{
    max-width: 387px;
}

/*
 * @component CardCuenta
 * @description Estilo para la tarjeta bancaria
 */
.card-cuenta{
    border-radius: 20px !important;
    margin-right: 1rem !important;
    min-height: 219px;
    width: 370px;
    box-shadow: 1px 3px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 5px !important;
}

/*
 * @component CardCuenta
 * @description Estilo para el nombre de la cuenta
 */
.card-cuenta .cuenta-nombre{
    font-size: 18px;
    color: #FFFFFF;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo principal de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title{
    font-weight: bold;
    font-size: 30px;
    color: #FFFFFF;
    margin-top: 0.9rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-description{
    font-weight: bold;
    font-size: 18px;
    color: #FFFFFF;
    margin-bottom: 0.5rem;
}

/*
 * @component CardCuenta
 * @description Estilo para el saldo secundario de la cuenta
 */
.card-cuenta .card-saldo-sub-meta .ant-card-meta-title small{
    font-size: 14px;
}

/*
 * @component CardGains
 * @description Estilo para los cards de profit y loss
 */
.card-gains{
    margin-bottom: 1rem !important;
    font-family: Inter;
    font-style: normal;
}

/*
 * @component CardGains
 * @description Estilo para el titulo del card
 */
.card-gains .card-title{
    font-weight: 600;
    font-size: 16px;
    color: #27272E;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .text-paid{
    font-weight: normal;
    font-size: 14px !important;
    color: #84859E;
}
/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots{
    padding-right: 5px;
}

/*
 * @component CardGains
 * @description Estilo para los montos del card
 */
.card-gains .dots svg{
    transform: rotate(90deg);
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .green-box{
    background: rgba(29, 210, 0, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box{
    background: rgba(229, 10, 10, 0.1);
    height: 48px;
    width: 50px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
}

/*
 * @component CardGains
 * @description Estilo para el icon del card
 */
.card-gains .red-box svg,
.card-gains .green-box svg{
    width: 25px;
}


/*
    @component CardProducto
*/
.card-store{
    min-width: 200px;
    border: none;
}

.card-store .ant-card-body{
    min-height: 295px; 
}

.card-store-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
}

.card-store-metadata{
    display: flex;
    flex-direction: column;
    row-gap: 4px;
}

.card-store-metadata .ant-typography{
        margin-bottom: 0 !important;
}
