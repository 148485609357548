.footer-public{
    width: 100%;
    margin: 3em 0 0 0 ;
    padding: 0 !important; 
    text-align: center;
    justify-content: center;
    
    
    .footer-section {
        margin: 0 auto ;
        max-width: 1300px;;
    }
    
    .section-boletin {
        background: #CCFF1B;
        padding: 60px 0 60px 0 ;

        .section-title{
            color: white !important;
        }

        .section-content{
            color: #ffffffb0 !important;
        }

        .section-boletin-email{
            margin-top: 2em;
            margin-bottom: 2em;
        }

    }

    .section-menu {

        

    }


    .boletin-footer{
        h1 .ant-typograpgy{
            font-family: 'Roboto', sans-serif !important;
            text-transform: uppercase !important;
        }
    }





}