
/*
* @component Layout -> Content
* @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla  menos el tamaño del pageHeader
*/
.layout-admin{

    /*
    * @component Layout -> Content
    * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla  menos el tamaño del pageHeader
    */
    .admin-content{
        height: calc(100vh - 100px );
    }
    /*
    * @component ProjectManager -> Content
    * @description background color del content de la vista de project manager
    */
    .content-bg {
        background: #F4F8FB;
    }

    /*
    * @component ProjectManager -> Col
    * @description background color del col de los card de task
    */

    .col-list-task-bg {
        background: #F2EFF5;
    }

    /*
    * @component CardTaskClient -> List
    * @description Elimina el border-bottom del los items de la lista
    */

    .list-border-none ul .ant-list-item {
        border: none!important;
    }

    /*
    * @component Checkbox 
    * @description Hace el checkbox redondo
    */

    .custom-checkbox-circle .ant-checkbox-checked::after {
        border-radius: 50px!important;
        border: 1.5px solid #2C3E50;
        height: 20px;
        width: 20px;
    }

    /*
    * @component Checkbox 
    * @description Acomoda el input dentro del checkbox
    */

    .custom-checkbox-circle .ant-checkbox {
        left: -3px;
        top: -2px;
    }

    /*
    * @component Checkbox 
    * @description Hace el checkbox inner redondo
    */

    .custom-checkbox-circle .ant-checkbox input, .custom-checkbox-circle .ant-checkbox .ant-checkbox-inner {
        border-radius: 50px;
        border: 1.5px solid #2C3E50;
        height: 20px;
        width: 20px;
    }

    /*
    * @component Divider 
    * @description cambia el color del divider de projectManager
    */

    .border-task {
        border: 1px solid #BDBDFE;
    }

    /*
    * @component RangePicker 
    * @description Hace el ranger transparent con bordes blancos y cuadrado
    */

    .filter-range {
        background: transparent!important;
        border: 1px solid #fff!important;
        color: #fff!important;
        border-radius: 0px !important;
        height: 30px;
    }

    /*
    * @component RangePicker 
    * @description Hace el ranger icon y el placeholder blancos
    */

    .filter-range .ant-picker-input input::placeholder, .filter-range .ant-picker-suffix .anticon>svg,
    .filter-range .ant-picker-input .ant-picker-range-separator .ant-picker-separator .anticon>svg{
        color: #fff!important;
    }

    /*
    * @component RangePicker 
    * @description El borderBottom cuando seleccionas un campo lo pone blanco
    */

    .filter-range .ant-picker-active-bar {
        background: #fff!important;
    }

    /*
    * @component Button 
    * @description Hace el borde transparente
    */

    .button-borderless {
        border: 1px solid transparent!important;
    }

    /*
    * @component Button 
    * @description Boton de descarga de los filtros, lo hace mas grande y el icono blanco
    */

    .button-download, .button-download svg {
        font-size: 25px!important;
        color: #fff;
    }

    /*
    * @component Header 
    * @description Cambia el color del Header y la fuente
    */

    .ant-layout-header {
        color: #FFFFFF;
        line-height: 64px;
        background: #8C11FF!important;
    }

    /*
    * @component PageHeader 
    * @description Cambia el color del PageHeader y la fuente, 
    * se esta sobreescribiendo la clase nativa ya que
    * no hay variables para modificarlo
    */

    .ant-page-header {
        box-sizing: border-box;
        margin: 0;
        color: #fff;
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum';
        position: relative;
        padding: 16px 24px;
        background-color: #bbea18!important;
    }

    /*
    * @component PageHeader 
    * @description Cambia  la fuente del titulo del PageHeader, 
    * se esta sobreescribiendo la clase nativa ya que
    * no hay variables para modificarlo
    */

    .ant-page-header-heading-title {
        margin-right: 12px;
        margin-bottom: 0;
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 3px;
        color: #FFFFFF!important;
        font-weight: 600;
        font-size: 20px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    /*
    * @component Button 
    * @description Ajusta el boton del Upload a la esquina inferior derecha 
    */
    .btn-upload-delete{
        display: block;
        position: absolute!important;
        bottom: 20px;
        right: 0%;
    }

    .header-list{
        text-transform: uppercase;
    }

}
