.section-boletin-email {
    padding: 8px 7px;
    border-radius: 10px;
    background: white;
    width: 90%;
    margin: auto;
}

.btn-boletin {
    background: #565656 !important;
    padding: 10px 30px !important;
    border-radius: 12px !important;
    color: #FFFFFF !important;
    font-size: 14px !important;
    height: auto !important;
    width: auto !important;
    font-style: normal;
    font-weight: bold;
}



.btn-boletin:focus,
.btn-boletin:active,
.btn-boletin:hover {
    background: #818181 !important;
    color: white !important;
    border: none !important;
}


.inp-boletin {
    width: calc(100% - 160px) !important;
}

.pie-footer {
    display: block;
    padding: 20px 1rem 10px 1rem !important;
    border-top: solid 1px #C4C4C4;
}

.row-cnt-footer,
.row-pie-footer {
    max-width: 1300px !important;
    margin: auto;
}


.pie-footer img {
    width: 90%;
}

.cnt-footer {
    padding: 100px 1rem 30px 1rem;

}

.btn-socialNet {
    background: #f3f3f3 !important;
    border-radius: 10px !important;
    margin: 5px;
    width: 40px !important;
    height: 40px !important;
    padding: 0px !important;
    padding-top: 8px !important;
    border: none !important;
    color: #999 !important;
}

.btn-socialNet:focus,
.btn-socialNet:active,
.btn-socialNet:hover {
    background: #e8d0ff !important;
    color: #CCFF1B !important;
    border: none !important;

}

.footer-links {
    list-style: none !important;
    padding: 0px;
}


.footer-links li {
    font-weight: normal !important;
    margin-bottom: 12px !important;
}

.footer-links a {
    color: black !important;
    font-weight: normal !important;
    font-size: 13px;
    font-weight: 200;
}

.txt-morado,
.footer-links a:hover {
    color: #CCFF1B !important;
}

.cnt-footer p {
    padding-right: 30px !important;
}

.cnt-footer h5 {
    margin-bottom: 25px !important;
    font-weight: 700 !important;
    margin-top: 10px !important;
}


.btn-chat {
    border-radius: 12px !important;
    border: 1px solid #3D7BD8 !important;
    box-sizing: border-box !important;


    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: #3D7BD8 !important;
    padding: 10px 15px !important;
    width: auto !important;
    height: auto !important;

}

.boletin-footer h1 {
    font-family: 'Roboto', sans-serif !important;
    text-transform: uppercase !important;
    font-size: 16px !important;
}

.boletin-footer .ant-typography{
    font-family: 'Roboto', sans-serif !important;
    color: #535b65;
    font-size: 16px !important;
}

.boletin-footer .ant-form-item-control-input input{

    border-radius: 0 !important;
    border: 2px solid #dbdbdb !important;
    background-color: transparent !important;
    padding: 10px 17px 10px 17px !important;
    width: 464px !important;

 }

 .boletin-footer .ant-form-item-control-input button{

    border-radius: 0 !important;
    background-color: transparent !important;
    color: #000000 !important;
    border: 2px solid #CCFF1B !important;
    height: 46px !important;
    width: 100px !important;
     
 }

 .boletin-footer .ant-form-item-control-input button:hover{

    border-radius: 0 !important;
    background-color: #CCFF1B !important;
    color: #000000 !important;
     
 }