
/*
 * @component Listas para el despliege de registros generales
 * @description Quita el border inferior de los elementos de la lista
 */

.component-list .ant-list-header,
.component-list .component-list-item{
    border:  none;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Quita padding superior del los elementos, para qu eno queden tan separados
 */
.component-list .component-list-item{
    padding-top: 0px;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Los cards dento de la lista tomen todo el ancho 
 */
.component-list .card-list{
    width: 100%;
}

/*
 * @component Listas para el despliege de registros generales
 * @description Da mas espacion interior a los cards de las lista
 */
.component-list .card-list .ant-card-body{
    padding: 13px;
    position: relative;
}

/*
 * @component Listas para el despliege de registros generales
 * @description pequeño linea de color que se encuentra en el lado izquierdo en cada elemento de la lista
 */
.component-list .card-list .badge-card{
    width: 6px;
    height: 45px;
    position: absolute;
    left: -2px;
    border-radius: 2px;
}


/*
 * @component imagenes
 * @description muestra ñas imagenes como background de un div para que no se deforma con diferentes tamaños
 */

.bg-image{
    background-color: #cccccc; 
    background-position: center;
    background-repeat: no-repeat; 
    background-size: cover;
}

/*
 * @component imagenes
 * @description muestra ñas imagenes como background de un div para que no se deforma con diferentes tamaños
 */

.bg-image.main{
    height: 100vw;
    max-height: 380px;
    max-width: 400px;
    width: 100%;
}
/*
 * @component imagenes
 * @description muestra ñas imagenes como background de un div para que no se deforma con diferentes tamaños
 */

.bg-image.small{
    height: 35px;
    width: 35px;
    border:  solid 2px #D0CFCF;
    border-radius: 5px;
    display: inline-block;
    margin-right: 5px;
    margin-top: 5px;
}

/*
 * @component imagenes
 * @description muestra ñas imagenes como background de un div para que no se deforma con diferentes tamaños
 */

.bg-image.selected{
    border:  solid 2px #CCFF1B !important;
}

/*
 * @component imagenes
 * @description muestra ñas imagenes como background de un div para que no se deforma con diferentes tamaños
 */

.modal-image .ant-modal-body{
    padding: 10px;
}

.search-bar.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child{
    top:  -2px;
}

/*
* Clases globales que se pueden implementar en cualquier parte del sistema
* Son para mover elementos a una cierta posicion o aplicar espacios
*/

.border{
    border: solid 1px red;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

.flex-left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-left-column {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.flex-right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-column-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-left-column-between {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
}

.flex-left-column-around {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-direction: column;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.p-relative {
    position: relative
}

.heigth-100 {
    height: 100% !important;
}

.p-0 {
    padding: 0!important;
}
.p-1 {
    padding: 1rem !important;
}

.pd-1 {
    padding: 1rem !important;
}

.pd-2 {
    padding: 2rem !important;
}

.pd-3 {
    padding: 3rem !important;
}

.pt-1 {
    padding-top: 1rem !important;
}

.pt-05 {
    padding-top: 0.5rem!important;
}

.pt-2 {
    padding-top: 2rem !important;
}

.pl-1 {
    padding-left: 1.5rem !important;
}

.pl-2 {
    padding-left: 2rem !important;
}

.pl-3 {
    padding-left: 3rem !important;
}

.pr-1 {
    padding-right: 1rem !important;
}
.pr-2{
    padding-right: 2rem !important;
}
.pb-1{
    padding-bottom:1rem!important;
}
.pb-2 {
    padding-bottom: 2rem !important;
}

.pb-3 {
    padding-bottom: 3rem !important;
}

.m-0 {
    margin: 0 !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.mr-3 {
    margin-right: 3rem !important;
}


.ml-1 {
    margin-left: 1rem !important;
}

.ml-2 {
    margin-left: 2rem !important;
}

.ml-3 {
    margin-left: 3rem !important;
}

.mt-1 {
    margin-top: 1rem !important;
}

.mt-2 {
    margin-top: 2rem !important;
}

.mt-3 {
    margin-top: 3rem !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mb-3 {
    margin-bottom: 3rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-2 {
    margin-right: 2rem !important;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}

.text-white{
    color: #FFF !important;
}

.text-gray{
    color: #858997 !important;
}

.text-gray-dark{
    color: #222222 !important;
}

.text-gray-dark{
    color: #222222 !important;
}

.text-blue{
    color: #CCFF1B !important;
}

.text-underline{
    color: #8D8D8D !important;
    text-decoration-line: line-through;
}

.width-100, .w-100 {
    width: 100% !important;
}

.width-80, .w-80 {
    width: 80% !important;
}

.heigth-100, .h-100 {
    height: 100% !important;
}

/* checkbox */
.checkbox-bdf .ant-checkbox-wrapper{
    margin: 0 !important;
}

.checkbox-bdf  .ant-checkbox .ant-checkbox-inner {
    background: transparent;
    border: 1px solid #CCFF1B;
    border-radius: 2px !important;
}

.checkbox-bdf .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
    background: #CCFF1B;
    border: 1px solid #CCFF1B !important;
}




/*Scroll*/
::-webkit-scrollbar
 {
    width: 8px  !important;     /* TamaÃ±o del scroll en vertical */
    height: 8px !important;    /* TamaÃ±o del scroll en horizontal */
    background-color:#000;
    border-radius: 5px;
}

/* Ponemos un color de fondo y redondeamos las esquinas del thumb */
::-webkit-scrollbar-thumb {
    border-radius: 5px !important;
    background-color: #CCFF1B;
}

/* Cambiamos el fondo y agregamos una sombra cuando estÃ© en hover */
::-webkit-scrollbar-thumb:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
    padding:0.5px !important;
    background-color: #CCFF1B;
}

/* Cambiamos el fondo cuando estÃ© en active */
::-webkit-scrollbar-thumb:active {
  background-color: #CCFF1B;
}

::-webkit-scrollbar-track {
    background: #BBB;
    border-radius: 5px !important;
}
